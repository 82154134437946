import { type FC } from 'react';
import classNames from 'classnames';
import NavHomeIcon from 'assets/icons/NavHomeIcon';
import NavRewardsIcon from 'assets/icons/NavRewardsIcon';
import NavMeIcon from 'assets/icons/NavMeIcon';
import styles from './SectionFooter.module.css';
import NavTile from './NavTile';

const SectionFooter: FC<{ hidden?: boolean }> = ({ hidden }) => (
  <footer
    className={classNames(styles.footer, hidden && styles.hidden)}
    aria-hidden={hidden}
  >
    <nav className={styles.nav}>
      <NavTile name="Home" icon={NavHomeIcon} route="/home" />
      <NavTile name="Rewards" icon={NavRewardsIcon} route="/rewards" />
      <NavTile name="Me" icon={NavMeIcon} route="/me" />
    </nav>
  </footer>
);

export default SectionFooter;
