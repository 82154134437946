import { type FC, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TextStyle } from 'assets/styles/styles';
import { offersQuery } from 'query/queries';
// import { categories } from 'assets/utils';
import OffersList from 'routes/sections/rewards/OfferList';
import gridIcon from 'assets/icons/icon-grid.svg';
import listIcon from 'assets/icons/icon-row.svg';
import styles from './AvailableOffers.module.css';

const AvailableOffers: FC = () => {
  const { data: offers, isLoading, error } = useQuery(offersQuery);
  const [isRow, setIsRow] = useState(true);
  const [selectedCategory, setSelectedCategory] =
    useState<string>('all offers');

  const categories = useMemo(() => {
    if (!offers) return [];

    return Array.from(new Set(offers.map((offer) => offer.category)));
  }, [offers]);

  const filteredOffers = offers?.filter(
    (offer) =>
      selectedCategory === 'all offers' || offer.category === selectedCategory,
  );

  return (
    <div className={styles.availableOffers}>
      <div className={styles.availableOffersHeader}>
        <h2 className={TextStyle.P1Bold}>All offers</h2>
        <button
          type="button"
          onClick={() => setIsRow(!isRow)}
          className={styles.availableOffersHeaderButton}
        >
          <img src={isRow ? listIcon : gridIcon} alt="grid" />
        </button>
      </div>
      <div className={styles.availableOffersCategories}>
        <button
          type="button"
          className={`${styles.availableOffersCategoryButton} ${
            selectedCategory === 'all offers' ? styles.active : ''
          }`}
          onClick={() => setSelectedCategory('all offers')}
        >
          All offers
        </button>
        {categories.map((category) => (
          <button
            key={category}
            type="button"
            className={`${styles.availableOffersCategoryButton} ${
              selectedCategory === category ? styles.active : ''
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      {isLoading && <>Loading&hellip;</>}
      {error && <>Could not load offers</>}
      {filteredOffers !== undefined && (
        <OffersList offers={filteredOffers} isRow={isRow} />
      )}
    </div>
  );
};

export default AvailableOffers;
