import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { KardIncomingTransaction, KardOffer, KardUser } from './kardTypes';

export interface RewardPayload {
  type: string;
  attributes: {
    message: string;
    name: string;
  };
  id: string;
  relationships: {
    user: {
      data: {
        id: string;
      };
    };
  };
}

interface NotificationState {
  isVisible: boolean;
  reward: RewardPayload | null;
}

type PartialNotificationState = Partial<NotificationState>;

export interface AppContextType {
  isBannerVisible: boolean;
  onBannerChange: () => void;
  displayOverlay: boolean;
  onOverlayChange: () => void;
  displayHomeOverlay: boolean;
  onHomeOverlayChange: () => void;
  currentUser: KardUser | null;
  setUser: (user: KardUser | null) => void;
  currentTransaction: KardIncomingTransaction | null;
  setTransaction: (transaction: KardIncomingTransaction | null) => void;
  currentOffer: KardOffer | null;
  setOffer: (offer: KardOffer | null) => void;
  notification: NotificationState;
  setNotification: (state: PartialNotificationState) => void;
  onNotificationChange: (reward?: RewardPayload) => void;
}

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((prev) => !prev), []);

  return [state, toggle] as const;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useApp = (): AppContextType => {
  const context = useContext(AppContext);

  if (!context) throw new Error('useApp must be used within a AppProvider');

  return context;
};

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isBannerVisible, onBannerChange] = useToggle();
  const [displayOverlay, onOverlayChange] = useToggle();
  const [displayHomeOverlay, onHomeOverlayChange] = useToggle();

  const [currentUser, setUser] = useState<KardUser | null>(null);
  const [currentTransaction, setTransaction] =
    useState<KardIncomingTransaction | null>(null);
  const [currentOffer, setOffer] = useState<KardOffer | null>(null);
  const [notification, setNotificationState] = useState<NotificationState>({
    isVisible: false,
    reward: null,
  });

  const setNotification = useCallback((state: PartialNotificationState) => {
    setNotificationState((prev) => ({ ...prev, ...state }));
  }, []);

  const onNotificationChange = useCallback((reward?: RewardPayload) => {
    setNotificationState((prev) => ({
      ...prev,
      isVisible: !prev.isVisible,
      ...(reward && { reward }),
    }));
  }, []);

  const value = useMemo(
    () => ({
      isBannerVisible,
      onBannerChange,
      displayOverlay,
      onOverlayChange,
      displayHomeOverlay,
      onHomeOverlayChange,
      currentUser,
      setUser,
      currentTransaction,
      setTransaction,
      currentOffer,
      setOffer,
      notification,
      setNotification,
      onNotificationChange,
    }),
    [
      isBannerVisible,
      onBannerChange,
      displayOverlay,
      onOverlayChange,
      displayHomeOverlay,
      onHomeOverlayChange,
      currentUser,
      setUser,
      currentTransaction,
      setTransaction,
      currentOffer,
      setOffer,
      notification,
      setNotification,
      onNotificationChange,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
