import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import RewardsHeader from './RewardsHeader';
import styles from './RewardsLayout.module.css';
import RewardsOverlay from './RewardsOverlay';

const RewardsLayout: FC = () => (
  <div className={styles.main}>
    <RewardsOverlay />
    <div className={styles.content}>
      <RewardsHeader />
      <main>
        <Outlet />
      </main>
    </div>
  </div>
);

export default RewardsLayout;
