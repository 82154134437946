import { FC, useState } from 'react';
import { useApp } from 'query/AppContext';
import { useNullablePubnub } from 'query/PubnubContext';
import { postTransaction } from 'query/mutations';
import KardIcon from 'assets/icons/kardIcon.svg';
import cardIcon from 'assets/icons/card.svg';
import styles from './SectionBanner.module.css';

const SectionBanner: FC = () => {
  const [error, setError] = useState(false);

  const {
    isBannerVisible,
    onOverlayChange,
    onBannerChange,
    currentTransaction,
  } = useApp();

  const pubnub = useNullablePubnub();

  if (!isBannerVisible) {
    return null;
  }

  const handleOverlay = () => {
    onBannerChange();
    onOverlayChange();

    postTransaction(currentTransaction!).catch(() => {
      setError(true);
    });
  };

  return (
    <div className={styles.app}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={KardIcon} alt="Kard" />
          <span className={styles.logoText}>Kard Demo</span>
        </div>
        {pubnub !== null && (
          <button
            type="button"
            className={styles.button}
            onClick={handleOverlay}
          >
            <img src={cardIcon} alt="card" />
            <span className={styles.buttonText}>Simulate a transaction</span>
          </button>
        )}
        {pubnub === null && (
          <p className={styles.connecting}>
            Connecting to notification service&hellip;
          </p>
        )}
      </div>
      {error && (
        <div className={styles.error}>
          <span>
            Something went wrong. Please refresh the page and try again.
          </span>
        </div>
      )}
    </div>
  );
};

export default SectionBanner;
