import { type FC, Fragment } from 'react';
import classNames from 'classnames';
import BulletIcon from 'assets/icons/BulletIcon';
import { KardLocation, KardOffer } from 'query/kardTypes';
import { TextStyle } from 'assets/styles/styles';
import cart from 'assets/icons/cart.svg';
import calendar from 'assets/icons/calendar.svg';
import styles from './Offer.module.css';
import { formatDistance, milesAwayFrom } from '../../Locations/distance';

const offerTypeLabels = {
  INSTORE: 'In-Store',
  ONLINE: 'Online',
};

export const getOfferDetails = (
  offer: KardOffer,
  location: KardLocation | undefined,
  full: boolean,
): string[] => [
  (offerTypeLabels[offer.offerType] ?? offer.offerType) +
    (full ? ' Offer' : ''),
  ...(location !== undefined
    ? [`${formatDistance(milesAwayFrom(location.geoLocation))} mi`]
    : []),
  `Expires ${new Date(offer.expirationDate).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
  })}`,
];

const OfferDetails: FC<{
  offer: KardOffer;
  location?: KardLocation;
  full?: boolean;
  icon?: boolean;
}> = ({ offer, location, full = false, icon = false }) => (
  <span className={classNames(TextStyle.Tiny, styles.details)}>
    {getOfferDetails(offer, location, full).map((detail, index, array) => (
      <Fragment key={detail}>
        <div className={icon ? styles.detail : ''}>
          {icon &&
            (detail.includes('Online') ? (
              <img
                src={cart}
                alt={offer.merchant.name}
                className={styles.detailIcon}
              />
            ) : (
              <img
                src={calendar}
                alt={offer.merchant.name}
                className={styles.detailIcon}
              />
            ))}
          <span>{detail}</span>
        </div>
        {!icon && index < array.length - 1 && <BulletIcon />}
      </Fragment>
    ))}
  </span>
);

export default OfferDetails;
