import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useApp } from 'query/AppContext';
import kardLogo from 'assets/icons/kardIcon.svg';
import handCard from 'assets/images/handCard.svg';
import styles from './RewardsOverlay.module.css';

const RewardsOverlay = () => {
  const { displayOverlay, currentTransaction, currentOffer } = useApp();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (displayOverlay) {
      setShowContent(false);
      timer = setTimeout(() => setShowContent(true), 2000);
    }

    return () => clearTimeout(timer);
  }, [displayOverlay]);

  return (
    <div
      className={`${styles.overlayContainer} ${
        displayOverlay ? styles.visible : ''
      }`}
    >
      {!showContent && (
        <div className={styles.kardIcon}>
          <img src={kardLogo} alt="Kard Logo" />
          <span className={styles.logoText}>Kard Demo</span>
        </div>
      )}

      {showContent && (
        <div className={styles.content}>
          <img src={handCard} alt="Hand Card" />
          <p className={styles.contentText}>
            Making an online purchase at
            <br /> {currentOffer?.name} for{' '}
            <span className={styles.amount}>
              ${((currentTransaction?.amount ?? 0) / 100).toFixed(2)}
            </span>
          </p>
          <span className={styles.spanContent}>
            Notification will appear immediately after the <br /> transaction is
            approved
          </span>
          <ClipLoader
            color="#3454E2"
            loading={displayOverlay}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
};

export default RewardsOverlay;
