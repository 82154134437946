import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { KardIncomingTransaction } from 'query/kardTypes';
import { postTransaction } from 'query/mutations';
import { useApp } from 'query/AppContext';
import closeIcon from 'assets/icons/closeIcon.svg';
import cardIcon from 'assets/icons/card.svg';
import Button from 'components/ButtonComponent';
import styles from './SectionNotification.module.css';

const SectionNotification: FC = () => {
  const {
    notification,
    onNotificationChange,
    currentTransaction,
    onHomeOverlayChange,
    currentOffer,
  } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const notificationElement = document.querySelector(
        `.${styles.notification}`,
      );

      if (
        notificationElement &&
        !notificationElement.contains(target) &&
        notification.isVisible
      ) {
        onNotificationChange();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [notification.isVisible, onNotificationChange]);

  const type = notification.reward?.type;

  const handleSeeMoreOffers = () => {
    onNotificationChange();
    navigate('/rewards');
  };

  const settledTransaction = {
    ...currentTransaction,
    status: 'SETTLED',
    settledDate: new Date().toISOString(),
  };

  const handleSimulateSettledTransaction = () => {
    postTransaction(settledTransaction as KardIncomingTransaction)
      .then(() => {
        onNotificationChange();
        setTimeout(() => {
          onHomeOverlayChange();
        }, 1500);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Transaction failed:', error));
  };

  return (
    <div>
      <div
        className={`${styles.notification}  ${
          notification.isVisible ? styles.visible : styles.hidden
        } `}
      >
        <button
          className={styles.closeButton}
          onClick={() => onNotificationChange()}
          aria-label="Close notification"
          type="button"
        >
          <img src={closeIcon} alt="" className={styles.closeIcon} />
        </button>
        <div className={styles.content}>
          <img
            src={currentOffer?.merchant.imgUrl}
            alt=""
            className={styles.logo}
          />
          <span className={styles.title}>Your Reward is on the way</span>
          <p className={styles.description}>
            {notification.reward?.attributes?.message}
          </p>
        </div>
        <div className={styles.buttons}>
          <Button onClick={handleSeeMoreOffers} variant="primary" size="large">
            See more offers
          </Button>

          {type === 'earnedRewardApproved' && (
            <Button
              onClick={handleSimulateSettledTransaction}
              variant="secondary"
              size="large"
            >
              <img src={cardIcon} alt="card" />
              <span>Simulate settled transaction</span>
            </Button>
          )}
          {type === 'earnedRewardSettled' && (
            <Button
              onClick={() => onNotificationChange()}
              variant="secondary"
              size="large"
            >
              <span>Dismiss</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionNotification;
