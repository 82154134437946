import { FC, useEffect, useState } from 'react';
import { useApp } from 'query/AppContext';
import styles from './SectionButton.module.css';

const SectionButton: FC = () => {
  const [showContent, setShowContent] = useState(false);
  const { isBannerVisible } = useApp();

  useEffect(() => {
    if (!isBannerVisible) {
      setShowContent(false);
    }
  }, [isBannerVisible]);

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className={styles.section}>
      <button
        type="button"
        className={styles.button}
        onClick={() => setShowContent(!showContent)}
      >
        Shop now
      </button>
      {showContent && (
        <span>Demo only. Button should link to merchant website.</span>
      )}
    </div>
  );
};

export default SectionButton;
