import { type FC, useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useApp } from 'query/AppContext';
import { KardIncomingTransaction } from 'query/kardTypes';
import { locationMapQuery, offersQuery, userQuery } from 'query/queries';
import glyphIcon from 'assets/icons/Glyph.svg';
import { EffectStyle, TextStyle } from 'assets/styles/styles';
import styles from './OfferPage.module.css';
import createTransaction from './createTransaction';
import Logo from '../Logo';
import MapContainer from '../Locations/MapContainer';
import OfferDetails from '../OfferList/Offer/OfferDetails';

const OfferPage: FC = () => {
  const { onBannerChange, setUser, setOffer, setTransaction } = useApp();
  const { offerId } = useParams();
  const locationId = useSearchParams()[0].get('locationId');

  const { data: offers } = useQuery(offersQuery);

  const offer = offers?.find((someOffer) => someOffer._id === offerId);

  const conditionalLocationMapQuery = {
    ...locationMapQuery,
    enabled: locationId !== null,
  };
  const { data: locationMap } = useQuery(conditionalLocationMapQuery);

  const location =
    locationId !== null ? locationMap.get(locationId) ?? null : null;

  const conditionalUserQuery = {
    ...userQuery,
    enabled: offer?.offerType === 'ONLINE',
  };
  const { data: user } = useQuery(conditionalUserQuery);

  useEffect(() => {
    if (offer?.offerType === 'ONLINE') {
      onBannerChange();
      return () => onBannerChange();
    }

    return undefined;
  }, [offer?.offerType, onBannerChange]);

  useEffect(() => {
    if (user) setUser(user);
  }, [user, setUser]);

  useEffect(() => {
    if (offer) setOffer(offer);
  }, [offer, setOffer]);

  const createNewTransaction = useCallback(() => {
    if (!user || !offer) return null;

    return createTransaction(user, offer);
  }, [user, offer]);

  useEffect(() => {
    const transaction = createNewTransaction();

    if (transaction) setTransaction(transaction as KardIncomingTransaction);
  }, [createNewTransaction, setTransaction]);

  return (
    <div className={styles.offer}>
      {offer !== undefined && (
        <img
          className={styles.banner}
          src={offer.merchant.bannerImgUrl}
          alt={`Large banner for ${offer.merchant.name}`}
        />
      )}
      <Logo
        size={75}
        merchant={offer?.merchant}
        className={classNames(EffectStyle.Shadowed, styles.logo)}
      />
      <div className={classNames(styles.details, TextStyle.P1)}>
        <div className={styles.summary}>
          <h1 className={TextStyle.H1}>
            {offer?.merchant.name ?? <>Loading&hellip;</>}
          </h1>
          {offer !== undefined && (
            <OfferDetails
              full
              offer={offer}
              location={location ?? undefined}
              icon
            />
          )}
          {location !== null && (
            <span className={classNames(TextStyle.Tiny, styles.light)}>
              {location.address.street} {location.address.city},{' '}
              {location.address.state}
            </span>
          )}
          {locationId !== null && location !== null && (
            <div className={styles.map}>
              <MapContainer
                locationMap={new Map([[locationId, location]])}
                focusedLocationId={locationId}
              />
            </div>
          )}
        </div>
        {offer !== undefined && (
          <>
            <div className={styles.cashback}>
              <div className={styles.deal}>
                <img
                  src={glyphIcon}
                  alt={offer.merchant.name}
                  className={styles.cashbackIcon}
                />
                <div>
                  <b className={TextStyle.P1Bold}>{offer.totalCommission}%</b>{' '}
                  cash back
                  {offer.maxRewardAmount &&
                    ` up to $${parseFloat(
                      (offer.maxRewardAmount / 100).toString(),
                    )}`}
                </div>
              </div>
            </div>
            <div className={styles.tnc}>
              <h2 className={styles.p1Bold}>About</h2>
              <p className={styles.text}>{offer.merchant.description}</p>
            </div>
            <div className={styles.tnc}>
              <h2 className={styles.p1Bold}>Terms and Qualifications</h2>
              <p className={styles.text}>{offer.terms}</p>
            </div>
            {location !== null && (
              <div className={styles.hours}>
                <h2 className={TextStyle.P1Bold}>Hours of Operation</h2>
                {Object.entries(location.operationHours).map(([day, hours]) => (
                  <div className={styles.dayHours}>
                    <span className={styles.day}>{day.toLowerCase()}</span>
                    <span>{hours}</span>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OfferPage;
