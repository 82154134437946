import ClipLoader from 'react-spinners/ClipLoader';
import { useApp } from 'query/AppContext';
import settledIcon from 'assets/icons/settledIcon.svg';
import styles from './HomeOverlay.module.css';

const HomeOverlay = () => {
  const { displayHomeOverlay } = useApp();

  return (
    <div
      className={`${styles.overlayContainer} ${
        displayHomeOverlay ? styles.visible : ''
      }`}
    >
      <div className={styles.content}>
        <img src={settledIcon} alt="Hand Card" className={styles.icon} />
        <p className={styles.contentText}>Settling the reward amount</p>
        <span className={styles.spanContent}>
          Transaction usually settles within 24-48 hours
        </span>
        <ClipLoader
          color="#3454E2"
          loading={displayHomeOverlay}
          size={35}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default HomeOverlay;
