import { FC } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { NullablePubnubProvider } from 'query/PubnubContext';
import { useApp } from 'query/AppContext';
import SectionFooter from './SectionFooter';
import styles from './SectionLayout.module.css';
import SectionBanner from './SectionBanner';
import SectionNotification from './SectionNotification';
import SectionButton from './SectionButton';

const SectionLayout: FC = () => {
  const { notification } = useApp();

  const hideFooter = useMatches().some(
    (match) =>
      typeof match.handle === 'object' &&
      match.handle !== null &&
      'hideFooter' in match.handle &&
      match.handle?.hideFooter === true,
  );

  return (
    <NullablePubnubProvider>
      <div className={styles.app}>
        <div
          className={`${styles.background} ${
            notification.isVisible ? styles.backgroundVisible : ''
          } `}
        >
          {' '}
        </div>
        <SectionBanner />
        <Outlet />
        <SectionFooter hidden={hideFooter} />
        <SectionNotification />
        <SectionButton />
      </div>
    </NullablePubnubProvider>
  );
};

export default SectionLayout;
