import { FC } from 'react';
import classNames from 'classnames';
import { KardOffer } from 'query/kardTypes';
import Offer from './Offer';
import styles from './OffersList.module.css';

const OffersList: FC<{ offers: KardOffer[]; isRow: boolean }> = ({
  offers,
  isRow,
}) => (
  <div className={classNames(styles.offersList, isRow && styles.row)}>
    {offers.map((offer) => (
      <Offer key={offer._id} offer={offer} isRow={isRow} />
    ))}
  </div>
);

export default OffersList;
