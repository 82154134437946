import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { offersQuery } from 'query/queries';
import { TextStyle } from 'assets/styles/styles';
import AddMoneyIcon from 'assets/icons/plus.svg';
import SendMoneyIcon from 'assets/icons/send.svg';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';
import { transactions } from 'assets/utils';
import HomeOverlay from './HomeOverlay';
import styles from './Home.module.css';

const Home: FC = () => {
  const { data: offers } = useQuery(offersQuery);
  const navigate = useNavigate();
  const openOffer = (offerId: string) => navigate(`/rewards/offers/${offerId}`);

  return (
    <main className={styles.main}>
      <HomeOverlay />
      <section className={styles.topSection}>
        <h1 className={TextStyle.H1Bold}>Home</h1>
        <div className={styles.balance}>
          <div className={styles.balanceContent}>
            <h2 className={styles.balanceTitle}>CASH BALANCE</h2>
            <h1 className={styles.balanceAmount}>$1,287.00</h1>
          </div>
          <div className={styles.balanceActions}>
            <div className={styles.balanceAction}>
              <img src={AddMoneyIcon} alt="Add money" />
              <span className={styles.balanceActionText}>Add money</span>
            </div>
            <div className={styles.balanceAction}>
              <img src={SendMoneyIcon} alt="Send money" />
              <span className={styles.balanceActionText}>Send money</span>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.rewards}>
        <div className={styles.rewardsHeader}>
          <h2 className={TextStyle.P1Bold}>Rewards</h2>
          <Link
            to="/rewards"
            className={classNames(TextStyle.CaptionBold, styles.linkWithIcon)}
          >
            Go to rewards{' '}
            <ChevronRightIcon fill="currentcolor" width={12} height={12} />
          </Link>
        </div>
        <span className={styles.rewardsDescription}>
          Shop with your linked debit card and earn cash back automatically.{' '}
          <span className={styles.learnMore}>Learn more</span>
        </span>
      </section>
      <section className={styles.logos}>
        {offers?.map((offer) => (
          <button
            type="button"
            className={styles.offers}
            onClick={() => openOffer(offer._id)}
          >
            <img
              src={offer.merchant.imgUrl}
              alt={offer.merchant.name}
              className={styles.offerImage}
            />
            <div className={styles.offerContent}>
              <span className={styles.offerCommission}>
                {offer.totalCommission}% cash back
              </span>
              <span className={styles.offerName}>{offer.name}</span>
            </div>
          </button>
        ))}
      </section>
      <div className={styles.divider} />
      <section className={styles.transactions}>
        <div className={styles.bar}>
          <h2 className={TextStyle.P1Bold}>Recent Transactions</h2>
        </div>
        <div className={styles.transaction}>
          {transactions.map((transaction) => (
            <div key={transaction.id} className={styles.transactionItem}>
              <img
                src={transaction.imgUrl}
                alt={transaction.merchant}
                className={styles.transactionImage}
              />
              <div className={styles.transactionContent}>
                <div className={styles.transactionInfo}>
                  <span className={styles.transactionName}>
                    {transaction.merchant}
                  </span>
                  <span className={styles.transactionDate}>
                    {transaction.date}
                  </span>
                </div>
                <span className={styles.transactionAmount}>
                  ${transaction.amount}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Home;
