import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { KardOffer } from 'query/kardTypes';
import { TextStyle } from 'assets/styles/styles';
import styles from './Offer.module.css';
import OfferDetails from './OfferDetails';
import Logo from '../../Logo';

const Offer = forwardRef<
  HTMLButtonElement,
  {
    offer: KardOffer;
    isRow: boolean;
  }
>(({ offer, isRow }, ref) => {
  const navigate = useNavigate();
  const openOffer = () => navigate(`/rewards/offers/${offer._id}`);

  return (
    <button
      className={isRow ? styles.offerRow : styles.offerGrid}
      ref={ref}
      type="button"
      onClick={openOffer}
    >
      <div
        className={isRow ? styles.logoStoreDetailsRow : styles.logoStoreDetails}
      >
        <Logo size={isRow ? 48 : 56} merchant={offer.merchant} />
        <div className={isRow ? styles.storeDetailsRow : styles.storeDetails}>
          {isRow && (
            <span className={styles.cashbackRow}>
              <span>{offer.totalCommission}%</span>
              <span>cash back</span>
            </span>
          )}
          <span className={isRow ? styles.offerName : TextStyle.CaptionBold}>
            {offer.name}
          </span>
          {/* TODO: instore distance */}
          <OfferDetails offer={offer} />
        </div>
      </div>
      <div className={styles.cashbackArrow}>
        {!isRow && (
          <span className={styles.cashback}>
            <span className={TextStyle.CaptionBold}>
              {offer.totalCommission}%
            </span>
            <span className={TextStyle.TinyBold}>cash back</span>
          </span>
        )}
      </div>
    </button>
  );
});

export default Offer;
