import cvs from 'assets/images/csv.svg';
import dell from 'assets/images/Dell_Logo.svg';
import allbirds from 'assets/images/Allbirds_logo.svg';

export const transactions = [
  { merchant: 'CVS', amount: '50', imgUrl: cvs, date: 'Today', id: 1 },
  { merchant: 'Dell', amount: '217.60', imgUrl: dell, date: 'Today', id: 2 },
  {
    merchant: 'Allbirds',
    amount: '125.46',
    imgUrl: allbirds,
    date: 'Today',
    id: 3,
  },
];

export const offers = [
  { merchant: 'CVS', amount: '50', imgUrl: cvs, date: 'Today', id: 1 },
];

export const categories = [
  'Food & Beverage',
  'Supplies & Services',
  'Department Stores',
  'Home & Garden',
  'Baby, Kids & Toys',
  'Occasions & Gifts',
  'Travel',
  'Miscellaneous',
  'Computers, Electronics & Software',
  'Sports & Outdoors',
  'Health & Beauty',
  'Pets',
  'Convenience',
  'Arts & Entertainment',
  'Clothing, Shoes & Accessories',
  'Gas',
  'Books & Digital Media',
];
