import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Pubnub, { ListenerParameters } from 'pubnub';
import { useQuery } from '@tanstack/react-query';
import { realtimeTokenQuery } from './queries';
import { RewardPayload, useApp } from './AppContext';

const PubnubContext = createContext<Pubnub | null>(null);

export default PubnubContext;

/**
 * Provides a Pubnub instance once it is ready to use.
 * @param param0 props (none required)
 * @returns a usable Pubnub instance, or null if not
 */
export const NullablePubnubProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pubnub, setPubnub] = React.useState<Pubnub | null>(null);
  const {
    onNotificationChange,
    onBannerChange,
    onOverlayChange,
    onHomeOverlayChange,
  } = useApp();
  const navigate = useNavigate();

  /**
   * Query for the configuration options needed to initialize the Pubnub instance.
   */
  const realtimeToken = useQuery(realtimeTokenQuery);

  // When configuration options are available, init Pubnub with them.
  useEffect(() => {
    const realtimeTokenData = realtimeToken.data;

    if (realtimeTokenData === undefined) {
      return setPubnub(null);
    }

    const newPubnub = new Pubnub({
      subscribeKey: realtimeTokenData.subscribeKey,
      userId: realtimeTokenData.uuid,
    });

    newPubnub.setToken(realtimeTokenData.token);

    setPubnub(newPubnub);

    return () => {
      setPubnub((prev) => {
        prev?.unsubscribeAll();
        return null;
      });
    };
  }, [realtimeToken.data]);

  // Subscribe to notifications
  useEffect(() => {
    if (pubnub === null) {
      return () => {};
    }

    const subscribeParams = {
      channels: [`broadcast-direct.${pubnub.getUUID()}`],
    };

    pubnub.subscribe(subscribeParams);
    return () => pubnub.unsubscribe(subscribeParams);
  }, [pubnub]);

  // Identify reward notifications, and display them as toasts
  useEffect(() => {
    const listenerParams = {
      message: (messageEvent) => {
        if (messageEvent.channel !== `broadcast-direct.${pubnub?.getUUID()}`) {
          return;
        }

        const payload: unknown = messageEvent.message;

        if (!(typeof payload === 'object' && payload !== null)) {
          return;
        }

        if (!('type' in payload && payload.type === 'reward')) {
          return;
        }

        if (
          !('reward' in payload) ||
          typeof payload.reward !== 'object' ||
          !payload.reward ||
          !('data' in payload.reward)
        ) {
          throw new Error(
            'Reward message missing reward field or invalid format',
          );
        }

        const { data } = payload.reward as { data: { type: string } };

        if (data.type === 'earnedRewardApproved') {
          onBannerChange();
          onOverlayChange();
          navigate('/home');
        }

        if (data.type === 'earnedRewardSettled') {
          onHomeOverlayChange();
        }

        setTimeout(() => {
          onNotificationChange(data as RewardPayload);
        }, 1000);
      },
    } as ListenerParameters;

    pubnub?.addListener(listenerParams);
    return () => {
      pubnub?.removeListener(listenerParams);
    };
  }, [
    pubnub,
    onNotificationChange,
    onBannerChange,
    onOverlayChange,
    onHomeOverlayChange,
    navigate,
  ]);

  return (
    <PubnubContext.Provider value={pubnub}>{children}</PubnubContext.Provider>
  );
};

export const useNullablePubnub = () => useContext(PubnubContext);
